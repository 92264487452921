import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import Layout from "../components/layout";
import "../assets/css/main.css";
import Markdown from "react-markdown";


const TermsPage = () => {
  const data = useStaticQuery(query);

  return (
    <Layout seo={data.strapiTermspage.seo}>
      <div className="bg-white">
        <div className="max-w-7xl mx-auto py-16 px-4 sm:py-10 sm:px-6 lg:px-8">
          <div className="text-center">
            <p className="mt-1 text-4xl font-extrabold text-gray-900 sm:text-5xl sm:tracking-tight lg:text-6xl">Terms & Conditions</p>
          </div>
        </div>
        <div className="max-w-7xl mx-auto pb-12 px-4 sm:px-6 lg:px-8">
          <Markdown source={data.strapiTermspage.content} escapeHtml={false} />
        </div>
      </div>
    </Layout>
  );
};

const query = graphql`
  query {
    strapiTermspage {
      content
      seo {
        metaTitle
        metaDescription
        shareImage {
          publicURL
        }
      }
    }
  }
`;

export default TermsPage;
